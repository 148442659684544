.hash-container {
    padding: 20px 10%;
    font-size: large;
    display: flex;
    align-items: center;
    height: 100%;
    
    img { 
        display: flex;
        margin: auto;
    }

    .ant-col {
        .row-input + .row-input {
            margin-top: 30px; 
        }
    }
}