@import '~antd/dist/antd.less';

@primary-color: rgb(38, 37, 43);
@secondary-color: rgb(166,166,166);
@tertiary-color: rgb(246, 91, 0);

body {
  background: @primary-color;
  background-image: url('assets/images/background.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: @secondary-color;
}

.App {
  height: 100vh;
}

.header-text { 
  color: @tertiary-color;
} 

.ant-divider, h2 {
  color: @secondary-color;
}

.ant-divider {
  border-top: 1px solid @secondary-color;
}

.ant-input, .ant-select-selector {
  background: transparent !important;
  background-color: transparent !important;
  border: 2px solid @secondary-color !important;
  border-radius: 8px !important;
  height: 45px !important;
  width: 100% !important;
}

.ant-select-arrow,
.ant-select,
.ant-input {
  color: white;
}

.ant-btn {
  background : @tertiary-color;
  width: 100%;
  border: unset; 
  color: white;
  height: 45px;
  font-size: 18px;
}

.ant-btn:hover,
.ant-btn:active,
.ant-btn:focus {
  background : @tertiary-color;
  color: white;
}

.ant-spin-dot-item {
  background-color: white;
}

.ant-select-selector {
  display: flex;
  align-items: center;
}